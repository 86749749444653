<template>
	<div
		v-if="showComponent"
		class="ecommerce-card-wrapper"
		:class="backgroundColor"
		:style="`padding: ${padding}; background-color: ${bgColor};`"
	>
		<TnSkeleton
			width="100%"
			height="100%"
			v-if="loading"
			class="skeleton"
			:style="`margin: -${padding}`"
			border-radius="s"
		/>
		<div
			ref="components"
			class="slots"
			:style="`gap: ${spacing}px; align-items: ${alignItems};`"
		>
			<slot>
				<component
					v-for="(component, i) in components"
					:key="i"
					:is="resolveComponent(component.template.ref.split('/').pop())"
					:class="loading ? 'loading' : ''"
					:component="component"
				/>
			</slot>
		</div>
	</div>
</template>
<script>
import componentList from "~/gizmo-components.ts";
export default defineNuxtComponent({
	name: "ECommerceCardWrapper",
	props: {
		components: {
			type: Array,
			default: () => [],
		},
		spacing: {
			type: Number,
			default: 0,
		},
		padding: {
			type: String,
			default: "0",
		},
		alignItems: {
			type: String,
			default: "flex-start",
		},
		staticComponent: {
			type: Boolean,
			default: false,
		},
		bgColor: {
			type: String,
			default: "white",
		},
	},

	data() {
		return {
			loading: true,
			componentsMounted: false,
		};
	},

  setup(props){
    const backgroundColor = useBackgroundColor(props.bgColor);
    return { backgroundColor }
  },

	computed: {
		showComponent() {
			if (!this.loading && this.componentsMounted === false && !this.staticComponent) {
				return false;
			}
			return true;
		},
	},
	methods: {
		resolveComponent(componentName) {
			const key = Object.keys(componentList).find((key) => key.toLowerCase() === componentName.toLowerCase());

			if (key) return componentList[key];
		},
		initializeComponent() {
			// Checks for mounted components in the slot, for this to work, it requires the root element to be a div with v-if
			if (this.components && this.components.length > 0) {
				const mo = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (
							Array.from(mutation.addedNodes).filter((n) => n.nodeName !== "#comment" && n.nodeName !== "#text")
								.length > 0
						) {
							this.componentsMounted = true;
							mo.disconnect();
						}
					});
					this.loading = false;
				});
				mo.observe(this.$refs.components, {
					childList: true,
					subtree: true,
					attributes: true,
				});
				// Check if there are any initial components in the slot, that load to fast for the observer
				const getChildNodes = () => {
					return Array.from(this.$refs.components.childNodes).filter(
						(node) => node.nodeName !== "#comment" && node.nodeName !== "#text",
					);
				};
				const hasInitialContent = getChildNodes();
				if (hasInitialContent.length > 0) {
					this.componentsMounted = true;
					mo.disconnect();
					this.loading = false;
				}
				// Some components might load to fast for the observer, so we need to check if there are any components in the slot after a delay
				setTimeout(() => {
					if (this.loading && !this.componentsMounted && !getChildNodes()?.length > 0) {
						this.loading = false;
					}
				}, "1000");
			}
		},
	},
	mounted() {
		if (this.staticComponent) {
			return (this.loading = false);
		} else {
			this.initializeComponent();
		}
	},
});
</script>
<style lang="scss" scoped>
.ecommerce-card-wrapper {
	border-radius: $spacing-s;
	border: 1px solid $color-neutrals-100-tint;
	position: relative;
	width: 100%;
}

.slots {
	display: flex;
	flex-direction: column;
}

.skeleton {
	position: absolute;
}

.loading {
	opacity: 0;
}
</style>
